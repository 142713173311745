import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass/styled-components';
import Image from '../image';
import { icons } from './icons';

export const Icon = ({ id, sx, ...props }) => {
  const url = icons[id];

  if (!url) {
    // eslint-disable-next-line no-console
    console.error(`Icon ${id} not found`);
    return null;
  }

  return (
    <Box as={Image} options={null} src={url} sx={{ width: '100%', height: '100%' }} {...props} />
  );
};

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
