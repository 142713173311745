import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass/styled-components';
import { Description, Title } from '../b-typography';

const ItemTitle = (props) => <Title mb="10px" {...props} />;

const ItemDescription = Description;

export const ColumnItem = ({ title, description, illustration, sx, ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
      {...props}
    >
      {illustration}
      <Box pr={['0px', '12px', '22px', '28px']}>
        <ItemTitle>{title}</ItemTitle>
        <ItemDescription>{description}</ItemDescription>
      </Box>
    </Box>
  );
};

ColumnItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  illustration: PropTypes.node,
  sx: PropTypes.object,
};
