export const icons = {
  'adaptive-boxed': 'https://ucarecdn.com/d4791020-15aa-4bbb-b330-114d34c23b22/adaptiveboxed.svg',
  'adaptive-video-boxed':
    'https://ucarecdn.com/a2eed1eb-fb7d-4b6d-b168-e9e83178df86/adaptivevideoboxed.svg',
  'adaptivedelivery-boxed':
    'https://ucarecdn.com/a4e238e2-402e-4968-857a-3fa2b35d944d/adaptivedeliveryboxed.svg',
  'arrows-boxed': 'https://ucarecdn.com/53e7b040-5d70-48dc-b129-eee5783fbfa8/arrowsboxed.svg',
  'atom-boxed': 'https://ucarecdn.com/fe32d799-c0a1-43a8-9f2b-2c74d7f8ee57/atomboxed.svg',
  'bucks-boxed': 'https://ucarecdn.com/2f800427-0cad-40e3-b836-8da7ee6e3036/bucksboxed.svg',
  'cart-plus-boxed': 'https://ucarecdn.com/ccb0fbd2-bdc9-4a0d-a8c9-7ccd6ef90b07/cartplusboxed.svg',
  'cycle-boxed': 'https://ucarecdn.com/5db5519d-292c-4526-8870-0d75dc8a9a09/cycleboxed.svg',
  'face-happy-boxed':
    'https://ucarecdn.com/b8c44f92-e6d3-4922-af85-9b7ff1141113/facehappyboxed.svg',
  'funnel-boxed': 'https://ucarecdn.com/5a8baf71-ffca-4ad2-be04-5310cb56359c/funnelboxed.svg',
  'graph-boxed': 'https://ucarecdn.com/235de08c-65d2-42c9-9924-da9910f56d1c/graphboxed.svg',
  'graph-bucks-boxed':
    'https://ucarecdn.com/0a1e35df-e2e0-4217-9c76-c4b567265990/graphbucksboxed.svg',
  'hand-boxed': 'https://ucarecdn.com/45058780-65dd-48c2-bdbf-aa4d95b965fd/handboxed.svg',
  'medal-boxed': 'https://ucarecdn.com/7d919b15-10e3-4255-933b-0653160d98c0/medalboxed.svg',
  'rocket-boxed': 'https://ucarecdn.com/b88d5b4b-308e-467d-a395-151c80710883/rocketboxed.svg',
  'shield-boxed': 'https://ucarecdn.com/4276fb28-7bec-4ad1-b7d7-87a4353fdb88/shieldboxed.svg',
  'stopwatch-boxed': 'https://ucarecdn.com/231d353d-e0cb-4a3f-9859-11fe158a8944/stopwatchboxed.svg',
  'brush-boxed': 'https://ucarecdn.com/37fed58b-75a1-4e49-888f-bb2d9c9fe020/brushboxed.svg',
  'checklist-boxed': 'https://ucarecdn.com/04f49dd8-cfed-4240-a483-d36b0c53e5bd/checklistboxed.svg',
  'checkmark-boxed': 'https://ucarecdn.com/63aaf1a3-bd22-4377-b13b-cd49bf47421c/checkmarkboxed.svg',
  'checkmark-circle-boxed':
    'https://ucarecdn.com/74900374-b3b4-4293-a069-7a0668c804f4/checkmarkcircleboxed.svg',
  'checkmark-gear-boxed':
    'https://ucarecdn.com/92711939-8577-43a2-9bf9-99ee551b45b8/checkmarkgearboxed.svg',
  'code-boxed': 'https://ucarecdn.com/1633c8c4-936b-4d63-8f27-74fbe4d98184/codeboxed.svg',
  'software-code-boxed':
    'https://ucarecdn.com/fdee68dc-48a8-4701-9356-cfef3825684c/softwarecodeboxed.svg',
  'gear-boxed': 'https://ucarecdn.com/25478e7a-d128-4997-b1c6-b1672fd63506/gearboxed.svg',
  'relax-boxed': 'https://ucarecdn.com/54f19ca3-9f7f-46c9-b985-fda41167fcbd/relaxboxed.svg',
  'settings-boxed': 'https://ucarecdn.com/e4309ffa-7df5-470b-87ed-a12db901897c/settingsboxed.svg',
  'cart-boxed': 'https://ucarecdn.com/c655c143-3da2-4587-a135-469f76ea8f12/cartboxed.svg',
  'chip-boxed': 'https://ucarecdn.com/0ec1139a-c076-43f5-b6df-097602393751/chipboxed.svg',
  'command-line-boxed':
    'https://ucarecdn.com/f42d4671-9aff-412e-beca-16526510edc8/commandlineboxed.svg',
  'file-boxed': 'https://ucarecdn.com/cec0376f-aacd-460b-9f9f-026971159937/fileboxed.svg',
  'image-boxed': 'https://ucarecdn.com/429cb93e-4770-45ef-b502-38e4c9006161/imageboxed.svg',
  'image-cdn-boxed': 'https://ucarecdn.com/efb90845-8a86-4958-a5e4-8fa2c2ebd517/imagecdnboxed.svg',
  'intelligence-boxed':
    'https://ucarecdn.com/2a24e800-1950-4150-821e-90ea2d8af179/intelligenceboxed.svg',
  'lightning-boxed': 'https://ucarecdn.com/da6e43f6-04e7-40be-876a-9af8030eca9e/lightningboxed.svg',
  'mobile-boxed': 'https://ucarecdn.com/bdcb1655-90a0-4c34-bdb8-919320f8b4a4/mobileboxed.svg',
  'pie-chart-boxed': 'https://ucarecdn.com/e3a1ac01-2a20-4a9c-afc8-5da37c34bdcb/piechartboxed.svg',
  'resize-boxed': 'https://ucarecdn.com/2ec435aa-ecf3-45d9-aea1-447543de0472/resizeboxed.svg',
  'transformations-boxed':
    'https://ucarecdn.com/785ede14-3591-4565-9ee1-865f0595be9b/transformationsboxed.svg',
  'uploader-boxed': 'https://ucarecdn.com/16a8b684-d282-4d93-aa05-127e5ebe5997/uploaderboxed.svg',
  'world-boxed': 'https://ucarecdn.com/3b8fe012-ad1b-4440-8c9f-d794f1387e25/worldboxed.svg',
  'speech-bubble': 'https://ucarecdn.com/f50d5e91-ab6b-4b91-ad04-ea837d9967f5/speechbubble.svg',
  'calendar-boxed': 'https://ucarecdn.com/c086e75f-0b78-4dd7-bbb1-7812769b46a7/calendarboxed.svg',
  'chat-boxed': 'https://ucarecdn.com/0a3e9c59-3d32-4930-ad6a-adb4bcc12ae4/chatboxed.svg',
  'mail-boxed': 'https://ucarecdn.com/4ce4592d-31a3-4f33-845b-7c7bcec8aca0/mailboxed.svg',
  'product-adaptivedelivery-color':
    'https://ucarecdn.com/94844cb6-d839-439b-8563-a857d148e20d/productadaptivedeliverycolor.svg',
  'product-adaptivedelivery-contour':
    'https://ucarecdn.com/5a8e6074-2bcf-4e29-bc3b-a31c6b8d2418/productadaptivedeliverycontour.svg',
  'product-intelligence-color':
    'https://ucarecdn.com/b3e6bc6b-6f43-4746-99e6-9cfead62c512/productintelligencecolor.svg',
  'product-intelligence-contour':
    'https://ucarecdn.com/0c81445d-a92a-4a7c-bbc7-147b32f15495/productintelligencecontour.svg',
  'product-transformation-color':
    'https://ucarecdn.com/598b7f69-48dc-4b9f-b38c-42b3b880410c/producttransformationcolor.svg',
  'product-transformation-contour':
    'https://ucarecdn.com/738ca71c-8060-48a0-9be5-c06c1f70b125/producttransformationcontour.svg',
  'product-uploader-color':
    'https://ucarecdn.com/018a3c97-538f-40ee-84e0-14ff9c832220/productuploadercolor.svg',
  'product-uploader-contour':
    'https://ucarecdn.com/2b24a2b3-df32-41af-a69b-e2c2d778fcd2/productuploadercontour.svg',
  'int-android': 'https://ucarecdn.com/29fcd500-00d3-44f5-878a-74dc85554ccf/intandroid.svg',
  'int-angular-1': 'https://ucarecdn.com/6cef2cfb-ef8e-40e7-822e-0da17ad962e1/intangular1.svg',
  'int-angular-2': 'https://ucarecdn.com/415d393a-d8f3-406d-8f3c-1c9bbd3da743/intangular2.svg',
  'int-go': 'https://ucarecdn.com/4e39937d-26a6-4221-8b80-0e842f26308e/intgo.svg',
  'int-java': 'https://ucarecdn.com/8e20e4a2-91b0-4a8c-b74b-589b153abc94/intjava.svg',
  'int-javascript': 'https://ucarecdn.com/ebfd4c27-7a15-4749-be5b-310b7e749deb/intjavascript.svg',
  'int-meteor': 'https://ucarecdn.com/8befac4a-7541-42ff-bdd2-826958d0bc36/intmeteor.svg',
  'int-objc': 'https://ucarecdn.com/50e5d576-e38d-4a9a-9360-c8c22d7cb8eb/intobjc.svg',
  'int-php': 'https://ucarecdn.com/60786898-3a37-4bda-875d-1d586a76be04/intphp.svg',
  'int-python': 'https://ucarecdn.com/3187620f-98c4-4c8e-9cc4-ebf7de14a03d/intpython.svg',
  'int-rails': 'https://ucarecdn.com/c52eac69-7571-4ffe-82d3-a1ff6d9e1519/intrails.svg',
  'int-react': 'https://ucarecdn.com/0a512aa1-c4c8-495f-bd7e-12c263437bbc/intreact.svg',
  'int-ruby': 'https://ucarecdn.com/46296725-cbfb-496d-ad0f-0b6efac03435/intruby.svg',
  'int-shopify': 'https://ucarecdn.com/39f82193-4f48-4e0c-b02a-1ce5a62002ae/intshopify.svg',
  'int-squarespace': 'https://ucarecdn.com/018899df-6bbf-43e4-b4de-03b246e340fe/intsquarespace.svg',
  'int-swift': 'https://ucarecdn.com/f1ddd72f-2705-4c00-a0df-b2be8175f229/intswift.svg',
  'int-wix': 'https://ucarecdn.com/66b77ae7-d1ab-48ed-8527-c361183f6394/intwix.svg',
  'int-zapier': 'https://ucarecdn.com/26d709d1-638b-471c-b598-99b7849ba8f1/intzapier.svg',
};
