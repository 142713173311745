import React from 'react';
import { BuzzLogoGrid, BuzzLogoIdEnum } from '@uc-common/buzz-logo';

export const ClientsBlock = () => {
  return (
    <BuzzLogoGrid
      ids={[
        BuzzLogoIdEnum.PANDADOC_BW,
        BuzzLogoIdEnum.PREZLY_BW,
        BuzzLogoIdEnum.WEBFLOW,
        BuzzLogoIdEnum.BRITISH_COUNCIL_BW,
        BuzzLogoIdEnum.MOZILLA,
        BuzzLogoIdEnum.STACKSHARE_BW,
        BuzzLogoIdEnum.TIMEPAD,
        BuzzLogoIdEnum.YCOMBINATOR_BW,
        BuzzLogoIdEnum.CHATRA,
        BuzzLogoIdEnum.TOTANGO_BW,
      ]}
      widths={[
        [104, 124, 134],
        [94, 112, 121, 123],
        [72, 87, 94],
        [92, 111, 120],
        [70, 84, 91],
        [108, 138, 149],
        [94, 115, 124],
        [114, 138, 149],
        [108, 129, 139],
        [108, 140],
      ]}
      containerSx={{
        maxWidth: '1280px',
        display: 'grid',
        gridGap: ['42px', '84px', '100px'],
        gridTemplateColumns: [
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(4, 1fr)',
          'repeat(5, 1fr)',
        ],
        color: '#000000',
      }}
      logoSx={[
        [true],
        [true],
        [true],
        [true],
        [true],
        [true],
        [true],
        [true],
        [true, true, false, true],
        [true, false, false, true],
      ].map((display) => ({
        display: display.map((flag) => (flag ? 'flex' : 'none')),
        px: 0,
      }))}
    />
  );
};
