import React from 'react';
import { Text } from 'rebass/styled-components';

export const H1 = (props) => (
  <Text
    as="h1"
    fontSize={['40px', '48px', '60px', '66px']}
    lineHeight="1.1em"
    fontWeight="bold"
    {...props}
  />
);

export const H2 = (props) => (
  <Text
    as="h2"
    fontSize={['36px', '44px', '50px', '54px']}
    lineHeight="1.1em"
    fontWeight="bold"
    {...props}
  />
);
