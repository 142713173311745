import React from 'react';
import { Box } from 'rebass/styled-components';
import PropTypes from 'prop-types';
import { ColumnItem } from './ColumnItem';

const ColumnIllustrationInlineIcon = ({ icon, sx, ...props }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '56px',
      width: '56px',
      marginLeft: ['0px', '-8px'],
      marginTop: ['-8px', '0px'],
    }}
    {...props}
  >
    {icon}
  </Box>
);

export const ColumnItemInlineIcon = ({ icon, title, description }) => {
  return (
    <ColumnItem
      title={title}
      description={description}
      illustration={
        <Box
          sx={{
            display: 'flex',
            flex: ['0 0 52px', 'initial'],
            justifyContent: ['center', 'flex-start'],
            marginRight: '19px',
          }}
        >
          <ColumnIllustrationInlineIcon icon={icon} />
        </Box>
      }
      sx={{ flexDirection: ['row', 'column'] }}
    />
  );
};

ColumnItemInlineIcon.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
};
