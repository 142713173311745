import PropTypes from 'prop-types';
import React from 'react';
import { Flex, Box } from 'rebass/styled-components';

export const Layout = ({ header, footer, children }) => {
  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Flex
        sx={{
          width: '100%',
          maxWidth: '1280px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {header}
        <Box
          as="main"
          sx={{
            width: '100%',
          }}
        >
          {children}
        </Box>
        {footer}
      </Flex>
    </Flex>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
};
