import { useImgSizes } from '@uc-common/use-img-sizes';
import { useImgSrcSet } from '@uc-common/use-img-srcset';
import { BuzzLogo, BuzzLogoIdEnum } from '@uc-common/buzz-logo';
import Image from '@uc-common/image';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';
import { Link } from '../Link';
import { ReactComponent as IconArrowLink } from './icons/arrow-link.svg';

const HoverableArrow = styled(IconArrowLink)`
  position: absolute;
  right: 0px;
  transform: translateX(-30px);
  transition: transform 0.3s;
`;

const HoverableArrowWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 11px;
  width: 104px;
  display: inline-block;
`;

const StyledLink = styled(Link)`
  transition: color 0.3s;

  &,
  &:active,
  &:visited,
  &:visited:active {
    color: #000000;
    text-decoration: none;
  }

  &:hover,
  &:focus,
  &:visited:hover,
  &:visited:focus {
    color: #6a717b;
    text-decoration: none;

    ${HoverableArrow} {
      transform: translateX(0px);
    }
  }
`;

const Avatar = ({ src }) => {
  const widths = [92, 82, 90, 99];

  const [srcSet, fallbackSrc] = useImgSrcSet({
    src,
    widths,
    filename: 'avatar.jpg',
    mapper: ({ src, width, dpi, filename }) =>
      `${src}-/scale_crop/${width}x${width}/smart/-/progressive/yes/-/format/auto/-/quality/${
        dpi > 1 ? 'lightest' : 'normal'
      }/${filename} ${width}w`,
  });
  const sizes = useImgSizes(widths);

  return (
    <Box
      as={Image}
      srcSet={srcSet}
      src={fallbackSrc}
      sizes={sizes}
      sx={{ borderRadius: '50px', width: widths, height: widths }}
    />
  );
};

const CaseStudy = ({ ppc = false }) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    pb={[55, 60, 70, 75]}
    pr={['0px', '32px', '45px', '80px']}
    pl={['15px', '32px', '45px', '80px']}
  >
    <Flex
      sx={{
        maxWidth: ['auto', '1120px'],
        width: '100%',
        flexWrap: ['wrap', 'nowrap'],
        flexFlow: ['column-reverse', 'row'],
      }}
    >
      <Box
        pr={['15px', '120px', '60px']}
        sx={{
          pb: ['60px', '76px', '82px', '96px'],
        }}
      >
        <Text
          fontSize={['24px', '24px', '26px', '27px']}
          lineHeight="150%"
          mb={['30px', '46px', '49px', '41px']}
          pr={['0px', '18px', '63px', '70px']}
        >
          &ldquo;Using Uploadcare, we’ve basically taken a shortcut. We&nbsp;haven’t had to build
          any uploading or image-processing infrastructure ourselves, so we’ve saved a&nbsp;bunch of
          developers’ work. This part of our software is&nbsp;just handled. It’s not something we
          need to worry about.&rdquo;
        </Text>
        <Text
          fontSize={['18px', '18px', '18px', '20px']}
          lineHeight={['22px', '22px', '22px', '24px']}
          fontWeight="bold"
        >
          {ppc ? (
            <StyledLink to="/accounts/signup/">
              Get started&nbsp;
              <HoverableArrowWrapper>
                <HoverableArrow />
              </HoverableArrowWrapper>
            </StyledLink>
          ) : (
            <StyledLink to="/customers/shogun/">
              Read case study&nbsp;
              <HoverableArrowWrapper>
                <HoverableArrow />
              </HoverableArrowWrapper>
            </StyledLink>
          )}
        </Text>
      </Box>
      <Box
        pr={['15px', '0px']}
        maxWidth={['100%', '170px', '228px', '269px']}
        width="100%"
        sx={{
          pb: ['25px', '50px', '50px', '50px'],
        }}
      >
        <Box
          mb="11px"
          justifyContent={['flex-end', 'flex-end', 'flex-start']}
          display={['none', 'flex']}
        >
          <BuzzLogo id={BuzzLogoIdEnum.SHOGUN_BW} width={[104, 124]} color="#B5B5B5" />
        </Box>
        <Text
          mb={['0px', '27px', '50px', '95px']}
          maxWidth={['100%', '100%', '220px', '220px']}
          display={['none', 'block']}
          fontSize={['18px', '20px']}
          lineHeight={['24px', '27px']}
          textAlign={['right', 'right', 'left']}
          color="#B5B5B5"
        >
          Reduced traffic costs by 86%
        </Text>
        <Box
          sx={{
            alignItems: 'flex-end',
            display: 'grid',
            gridGap: ['10px', '10px', '0px', '14px'],
            gridAutoFlow: ['column', 'row', 'column'],
            gridAutoColumns: ['1fr 1fr', '1fr', '1fr 90px', '1fr 99px'],
            gridTemplateRow: '1fr 1fr',
          }}
        >
          <Box display={['flex', 'none']} sx={{ gridArea: ['1 / 1', 'initial'] }}>
            <BuzzLogo id={BuzzLogoIdEnum.SHOGUN_BW} width={[104, 124]} color="#B5B5B5" />
          </Box>
          <Text
            fontSize={['18px', '18px', '18px', '20px']}
            lineHeight={['24px', '24px', '24px', '27px']}
            color="#B5B5B5"
            sx={{
              whiteSpace: 'nowrap',
              gridRow: ['2', 'initial'],
              textAlign: ['left', 'right', 'left'],
            }}
          >
            Finbarr Taylor
            <br /> CTO & founder
          </Text>
          <Flex
            alignItems="flex-end"
            justifyContent={['flex-end', 'flex-end', 'flex-start']}
            sx={{ gridRow: ['span 2', 'initial'] }}
          >
            <Avatar src="https://ucarecdn.com/f040562b-f862-43e4-88f9-abc172588e37/" />
          </Flex>
        </Box>
      </Box>
    </Flex>
  </Flex>
);

export const FourthScreen = ({ ppc = false }) => {
  return <CaseStudy ppc={ppc} />;
};
