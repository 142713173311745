import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass/styled-components';

export const Section = ({ sx, ...props }) => {
  return (
    <Box
      as="section"
      sx={{
        width: '100%',
        ...sx,
      }}
      {...props}
    />
  );
};

Section.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
