import { useEffect, useState } from 'react';
import { useCurrentBreakpoint } from '@uc-common/use-current-breakpoint';

export const useResponsiveValue = (valuesPerBreakpoint) => {
  const breakpoint = useCurrentBreakpoint();
  const [value, setValue] = useState(null);

  useEffect(() => {
    const idx =
      breakpoint < valuesPerBreakpoint.length - 1 ? breakpoint : valuesPerBreakpoint.length - 1;

    setValue(valuesPerBreakpoint[idx]);
  }, [breakpoint, valuesPerBreakpoint]);

  return value;
};
