import React from 'react';
import { BlImage } from '@uc-common/bl-image';
import styled, { useTheme } from 'styled-components';
import { useResponsiveValue } from './useResponsiveValue';

const unitify = (value) => `${value}px`;
const defaultMapper = ({ value }) => value;

const useResponsive = ({ values, mapper = defaultMapper }) => {
  const { breakpoints } = useTheme();
  const parsed = breakpoints.map((str) => parseInt(str, 10));
  const queries = [];

  // eslint-disable-next-line no-plusplus
  for (let index = 0, len = Math.min(values.length, parsed.length + 1); index < len; index++) {
    let minWidth;
    let maxWidth;
    const query = [];
    if (index !== 0) {
      minWidth = parsed[index - 1];
      query.push(`(min-width: ${unitify(minWidth)})`);
    }
    if (index < len - 1) {
      maxWidth = parsed[index] - 1;
      query.push(`(max-width: ${unitify(maxWidth)})`);
    }

    queries.push([query.join(' and '), mapper({ value: values[index], minWidth, maxWidth })]);
  }

  return queries;
};

const ExpansionBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Svg = ({ src, alt }) => <ExpansionBlock as="img" src={src} alt={alt} />;

const Svgs = ({ srcs, alt }) => {
  const queries = useResponsive({ values: srcs });

  return (
    <ExpansionBlock as="picture">
      {queries.map(([media, value]) => (
        <source srcSet={value} media={media} key={media} />
      ))}

      <Svg src={queries[0][1]} alt={alt} />
    </ExpansionBlock>
  );
};

const BlinkImg = ({ src, alt }) => <ExpansionBlock as={BlImage} alt={alt} data-blink-src={src} />;

const BlinkImgs = ({ srcs, alt }) => <BlinkImg src={useResponsiveValue(srcs)} alt={alt} />;

const OneOrMany = ({ array, one, many }) => (array.length === 1 ? one(array[0]) : many(array));

const extension = (url) => {
  return url
    .substring(1 + url.lastIndexOf('/'))
    .split('?')[0]
    .split('#')[0]
    .split('.')[1];
};

const SvgDetector = ({ srcs, alt }) => {
  const isSvg = srcs.every((src) => extension(src) === 'svg');
  const One = isSvg ? Svg : BlinkImg;
  const Many = isSvg ? Svgs : BlinkImgs;

  return (
    <OneOrMany
      array={srcs}
      one={(value) => <One src={value} alt={alt} />}
      many={(values) => <Many srcs={values} alt={alt} />}
    />
  );
};

export const Picture = ({ src, alt }) => (
  <SvgDetector srcs={Array.isArray(src) ? src : [src]} alt={alt} />
);
