import React from 'react';
import { Text } from 'rebass/styled-components';

export const Title = (props) => (
  <Text fontSize={['20px', '20px', '22px']} lineHeight="1.3em" fontWeight="bold" {...props} />
);

export const Description = React.forwardRef((props, ref) => (
  <Text
    ref={ref}
    fontSize={['16px', '16px', '18px']}
    lineHeight="1.4em"
    color="#626262"
    {...props}
  />
));
