import React from 'react';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { Picture } from './Picture';

const StyledTileBottom = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const StyledTileTop = styled(StyledTileBottom)`
  justify-content: space-between;

  clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
  transition:
    clip-path 0.45s,
    -webkit-clip-path 0.45s;
`;

const StyledContainer = styled.div`
  position: relative;
  outline: none;

  &:hover,
  &:focus {
    ${StyledTileTop} {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
`;

export const Tile = ({ title, description, illustrationSrc, sx, faceSx, backSx }) => (
  <Box tabIndex="0" as={StyledContainer} sx={sx}>
    <Box as={StyledTileBottom} sx={{ backgroundColor: '#F6F6F6', ...faceSx }}>
      <Box
        sx={{
          fontSize: ['18px', '18px', '20px', '22px'],
          lineHeight: '1.4em',
          fontWeight: 'bold',
          px: ['15px', '15px', '20px'],
          py: ['10px', '10px', '15px'],
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flex: '1',
          overflow: 'hidden',
        }}
      >
        <Picture src={illustrationSrc} />
      </Box>
    </Box>

    <Box
      as={StyledTileTop}
      sx={{
        px: ['15px', '15px', '20px'],
        py: ['10px', '10px', '15px'],
        ...backSx,
      }}
    >
      <Box
        sx={{
          fontSize: ['18px', '18px', '20px', '22px'],
          lineHeight: '1.4em',
          fontWeight: 'bold',
          pb: ['10px', '10px', '15px'],
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          fontSize: ['16px', '16px', '20px', '22px'],
          lineHeight: '1.4em',
        }}
      >
        {description}
      </Box>
    </Box>
  </Box>
);

export const Tiles = ({ children, sx }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: ['1fr', 'repeat(3, 3fr)'],
        gridAutoRows: ['180px', '141px', '188px', '226px'],
        gridGap: '8px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
