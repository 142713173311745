import React from 'react';
import { PaddingEnum, Section } from '@uc-common/b-layout';
import { Head, HeadVariantsEnum } from '@uc-common/b-head';
import { Button, ButtonTypeEnum } from '@uc-common/button';

import { Link } from '../Link';

export const GetStartedSection = ({ btnSignUpProps }) => (
  <Section
    as="article"
    sx={{
      paddingTop: PaddingEnum.VERTICAL_XL,
      paddingBottom: PaddingEnum.VERTICAL_L,
    }}
  >
    <Head
      variant={HeadVariantsEnum.V2}
      title="Ready to get started?"
      description=""
      cta={
        <>
          <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK} {...btnSignUpProps}>
            Get started
          </Button>
          <Button as={Link} to="/schedule-demo/" type={ButtonTypeEnum.DARK} ghost>
            Get a demo
          </Button>
        </>
      }
    />
  </Section>
);
