import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass/styled-components';
import { PaddingEnum } from '../b-layout';

export const Columns = ({ children, sx }) => {
  return (
    <Box
      sx={{
        px: PaddingEnum.HORIZONTAL_NORMAL,
        display: 'grid',
        gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
        gridGap: '35px 8px',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

Columns.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};
