import { Columns, ColumnItem } from '@uc-common/b-columns';
import { Head, HeadVariantsEnum } from '@uc-common/b-head';
import { Icon } from '@uc-common/b-icon';
import { Layout as BLayout, PaddingEnum, Section } from '@uc-common/b-layout';
import { VisualBig } from '@uc-common/b-visual-big';
import { FooterContainer } from '@uc-common/footer/styles/Footer.styles';
import styled from 'styled-components';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import React from 'react';
import { Box, Flex, Text } from 'rebass/styled-components';
import { Footer } from '../../components/Footer';
import { FourthScreen } from '../../components/forSem';
import { CommonLayout } from '../../components/Layout/CommonLayout';
import { Link } from '../../components/Link';
import { Navbar } from '../../components/Navbar';
import { Tiles, Tile } from '../../components/PotentialBuilderComponents/Tiles';
import { useResponsiveValue } from '../../components/PotentialBuilderComponents/useResponsiveValue';
import { ClientsBlock } from '../../components/ClientsBlock';
import { GetStartedSection } from '../../components/GetStartedSection';

const StyledFooter = styled(Footer)`
  ${FooterContainer} {
    border-top: none;
  }
`;

export const frontmatter = {
  title: 'Adaptive Delivery',
  description:
    'Adaptive Delivery is a frontend tool powered by a state-of-the-art CDN infrastructure. Simply add one line of code and mark all mission-critical image handling tasks as completed.',
  noindex: false,
};

const CustomTile = ({ title, description, illustrationSrc, imgSx }) => (
  <Tile
    title={title}
    description={description}
    illustrationSrc={illustrationSrc}
    backSx={{ backgroundColor: '#B2E3CE' }}
    sx={{ img: imgSx }}
  />
);

const DoubleTile = ({ title, description, illustrationSrc, imgSx }) => (
  <Tile
    title={title}
    description={description}
    illustrationSrc={illustrationSrc}
    backSx={{ backgroundColor: '#B2E3CE' }}
    sx={{
      gridArea: ['initial', 'span 2'],
      img: { objectFit: 'cover', ...imgSx },
    }}
  />
);

const ProductName = ({ text, icon }) => (
  <Box
    sx={{
      display: 'flex',
      px: PaddingEnum.HORIZONTAL_WIDE,
      paddingBottom: '15px',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    <Box
      sx={{
        width: ['38px', '44px'],
        height: ['38px', '44px'],
        marginLeft: '-4px',
      }}
    >
      <Icon id={icon} />
    </Box>
    <Text
      sx={{
        fontWeight: 'normal',
        fontSize: ['18px', '20px', '22px'],
        paddingLeft: ['8px', '10px'],
      }}
    >
      {text}
    </Text>
  </Box>
);

const AdaptiveDelivery = () => (
  <>
    <Navbar />
    <BLayout header={null} footer={null}>
      <Section
        sx={{
          paddingTop: PaddingEnum.VERTICAL_S,
        }}
      >
        <ProductName text="Adaptive Delivery" icon="product-adaptivedelivery-color" />
        <Head
          variant={HeadVariantsEnum.V1}
          title="Fully automated responsive images"
          description=""
          cta={
            <>
              <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                Get started
              </Button>
              <Button as={Link} to="/schedule-demo/" type={ButtonTypeEnum.DARK} ghost>
                Get a demo
              </Button>
            </>
          }
        />

        <Tiles
          sx={{
            paddingTop: PaddingEnum.VERTICAL_M,
            px: PaddingEnum.HORIZONTAL_NORMAL,
          }}
        >
          <DoubleTile
            title="AI-optimized compression"
            description="Applies the best size-to-quality ratio without visible artifacts."
            illustrationSrc={[
              'https://ucarecdn.com/854ce7eb-8166-49f9-a026-81cf99fdc1ed/adeliverycompressionmobi.svg',
              'https://ucarecdn.com/f80cb6e7-5e53-4730-9ca4-8bbd881f504f/adeliverycompression.svg',
            ]}
          />
          <CustomTile
            title="Retina"
            description="Serve correctly sized images to high pixel density devices."
            illustrationSrc="https://ucarecdn.com/7845eb45-04ba-464c-be18-9de6da8a9ec2/adeliveryretina.svg"
          />
          <CustomTile
            title="Lazy loading"
            description="Boost First Contentful Paint by loading offscreen images only on scroll."
            illustrationSrc="https://ucarecdn.com/2dbdaf69-8317-44ce-917d-6030133f4bad/adeliverylazyloading.svg"
            imgSx={{ objectFit: 'cover', px: ['15px', '15px', '20px'] }}
          />
          <CustomTile
            title="Responsiveness"
            description="Analyze the user's context and serve images tailored to specific screens."
            illustrationSrc="https://ucarecdn.com/622cb16c-5551-4701-9c97-ab0018b6ee05/adeliveryresponsiveness.svg"
          />
          <CustomTile
            title="On-the-fly transformations"
            description="Add custom server-performed operations like crop, filters, watermarks, and more."
            illustrationSrc="https://ucarecdn.com/784033ac-45f1-4a5e-b2f5-c27e25fed94c/adeliverytransformations.svg"
            imgSx={{ objectFit: 'cover' }}
          />
        </Tiles>
      </Section>
      <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
        <Head
          variant={HeadVariantsEnum.V2}
          title="One script to rule them all"
          description="Adaptive Delivery eliminates all image-related frontend routines. Simply add one line of code and mark all image-handling tasks as completed."
          cta={
            <>
              <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
                Integrate now
              </Button>
              <Button
                as={Link}
                to="/docs/delivery/adaptive_delivery/"
                type={ButtonTypeEnum.DARK}
                ghost
              >
                Explore docs
              </Button>
            </>
          }
          sx={{
            '& > div:nth-child(2)': {
              color: '#596570',
            },
          }}
        />
        <VisualBig
          src={
            useResponsiveValue([
              'https://ucarecdn.com/7b8c7b68-b67c-44f8-a409-9dc0bf661728/datablinksrcmobi.svg',
              'https://ucarecdn.com/dc3b9fec-46db-4c62-9401-0e0e7d63ec52/datablinksrc.svg',
            ]) || ''
          }
          sx={{
            paddingTop: PaddingEnum.VERTICAL_M,
          }}
        />
      </Section>
      <Section sx={{ paddingTop: PaddingEnum.VERTICAL_XL }}>
        <Head
          variant={HeadVariantsEnum.V3}
          title="Reduce unnecessary traffic"
          description=" and optimize your overall website performance with just one tool"
        />
        <VisualBig
          src={
            useResponsiveValue([
              'https://ucarecdn.com/c0155415-795f-4e67-8749-ae7d996552b6/compressionmobi.svg',
              'https://ucarecdn.com/f72587c4-aaee-41f5-b40f-e98f4c68005e/compression.svg',
            ]) || ''
          }
          sx={{
            paddingTop: PaddingEnum.VERTICAL_L,
            paddingBottom: PaddingEnum.VERTICAL_L,
          }}
        />
        <FourthScreen />
      </Section>
      <Section
        sx={{
          paddingTop: PaddingEnum.VERTICAL_L,
        }}
      >
        <Head
          variant={HeadVariantsEnum.V2}
          title="Comes as part of a robust ecosystem"
          description=""
        />
        <VisualBig
          src="https://ucarecdn.com/8540346b-2171-443d-a894-15892b23ccd4/adeliveryinfrastructure.svg"
          sx={{
            paddingTop: PaddingEnum.VERTICAL_M,
          }}
        />
        <Columns
          sx={{
            paddingTop: PaddingEnum.VERTICAL_S,
          }}
        >
          <ColumnItem
            title="Bi-directional CDN"
            description="With 288,000 acceleration nodes under the hood, Uploadcare ensures 4X faster uploads and 8.9X faster delivery."
          />
          <ColumnItem
            title="Powerful API"
            description="The friendly yet powerful API with extensive docs will ensure maximum workflow efficiency."
          />
          <ColumnItem
            title="Advanced data security"
            description="Data encryption, access control, backups and more with the full support of Uploadcare engineers."
          />
        </Columns>
      </Section>
      <GetStartedSection />
    </BLayout>{' '}
    <Flex
      width="100%"
      justifyContent="center"
      backgroundColor="#F6F6F6"
      py={['72px', '82px', '94px', '104px']}
      px={['28px', '97px', '86px', '89px']}
      mb={PaddingEnum.VERTICAL_M}
    >
      <ClientsBlock />
    </Flex>
    <StyledFooter />
  </>
);

export default ({ location }) => (
  <CommonLayout meta={frontmatter} pathName={location.pathname}>
    <AdaptiveDelivery />
  </CommonLayout>
);
