import PropTypes from 'prop-types';
import React from 'react';
import { Box } from 'rebass/styled-components';
import { ColumnItem } from './ColumnItem';
import { BlImage } from '../bl-image';
import { IllustrationCard } from './IllustrationCard';

const ColumnIllustrationCardImage = ({ src, ops, sx, ...props }) => (
  <IllustrationCard>
    <Box
      as={BlImage}
      data-blink-src={src}
      data-blink-ops={ops}
      alt=""
      sx={{
        display: 'block',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        ...sx,
      }}
      {...props}
    />
  </IllustrationCard>
);

export const ColumnItemCardImage = ({ imageSrc, imageOps, title, description, alt = '' }) => {
  return (
    <ColumnItem
      title={title}
      description={description}
      illustration={
        <Box mb="20px">
          <ColumnIllustrationCardImage src={imageSrc} ops={imageOps} alt={alt} />
        </Box>
      }
    />
  );
};

ColumnItemCardImage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageOps: PropTypes.string,
  alt: PropTypes.string,
};
