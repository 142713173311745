import React from 'react';
import Image from '@uc-common/image';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import { BlImage } from '@uc-common/bl-image';
import { Box, Flex, Text } from 'rebass/styled-components';
import styled from 'styled-components';

import { Link } from '../Link';
import { FlowHeading } from './FlowHeading';
import { ReactComponent as IconFile } from './icons/file-icon.svg';
import { ReactComponent as CardArrow } from './icons/card-arrow.svg';
import { ReactComponent as CardTurn } from './icons/card-turn.svg';
import { ReactComponent as ResultArrow } from './icons/result-arrow.svg';
import { ReactComponent as ResultTurn } from './icons/result-turn.svg';
import { ReactComponent as SmallArrowDown } from './icons/small-arrow-down.svg';

const StyledCardArrow = styled(CardArrow)`
  position: absolute;
  right: 0px;
`;
const StyledCardTurn = styled(CardTurn)`
  position: absolute;
  right: 0px;
`;

const StyledResultArrow = styled(ResultArrow)`
  position: absolute;
  left: 0px;
`;
const StyledResultTurn = styled(ResultTurn)`
  position: absolute;
  bottom: 0px;
`;

const ListItem = ({ text }) => (
  <Text
    as="li"
    sx={{
      fontSize: ['16px', '20px'],
      pb: 12,
      lineHeight: '150%',
      listStyle: 'none',
      '&::before': {
        content: "url('https://ucarecdn.com/0844ce33-536b-48c7-bfb5-27eda4d51538/li.svg')",
        verticalAlign: 'middle',
        lineHeight: '100%',
        fontSize: '28px',
        pr: '12px',
      },
    }}
  >
    {text}
  </Text>
);

const grayBgr = '#F6F7F8';
const cornerRadius = 12;

const SmallCard = ({ number, last = false, text, illustration }) => (
  <Flex
    sx={{
      flexDirection: ['row', 'column'],
      alignItems: 'start',
      justifyContent: 'space-between',
      width: '100%',
      height: ['auto', 'auto', 'auto', 314],
      position: 'relative',
      backgroundColor: grayBgr,
      borderRadius: cornerRadius,
    }}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        width: ['80%', '100%'],
        px: [15, 12, 15],
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: ['28px 1fr', '22px 1fr', '25px 1fr', '28px 1fr'],
          gridGap: ['0px'],
          pt: ['12px', '8px', '10px'],
        }}
      >
        <Text fontSize="25px" fontWeight="500" pb={['8px', '0px']}>
          {number}
        </Text>
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            height: ['45px', '48px', '48px', '45px'],
            my: ['0px', '-3px', '-3px', '0px'],
            width: '100%',
            display: ['none', 'initial'],
          }}
        >
          {last ? <StyledCardTurn /> : <StyledCardArrow />}
        </Box>
      </Box>
      <Box
        sx={{
          minHeight: ['unset', '60px', '55px', '75px'],
          width: '100%',
          borderRight: last ? ['none', '1px solid #BAC2CA'] : 'none',
          pr: last ? [0, 3, 3, 10] : 0,
        }}
      >
        <Text
          sx={{
            mt: ['0px', '-10px', '-5px', '0px'],
            fontSize: ['14px', '13px', '14px', '15px'],
            lineHeight: ['130%', '140%'],
          }}
        >
          {text}
        </Text>
      </Box>
    </Flex>
    <Box
      sx={{
        position: 'absolute',
        left: '13px',
        bottom: '9px',
        display: ['initial', 'none'],
      }}
    >
      <SmallArrowDown />
    </Box>
    {illustration}
  </Flex>
);

export const OptimizationScreen = ({ ppc = false }) => (
  <Flex
    alignItems="center"
    flexDirection="column"
    px={['15px', '32px', '45px', '80px']}
    pb={['60px', '80px', '100px', '100px']}
  >
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '1120px',
        width: '100%',
        borderTop: '1px solid #DEE4EA',
      }}
    >
      <Flex flexDirection="column" width="100%">
        <FlowHeading
          sx={{ my: ['20px', '40px'], pt: 20 }}
          heading="Automate images. "
          text="Get rid of the whole image preparation, compression and delivery routine."
          flow
        />
        <Box
          sx={{
            display: 'grid',
            gridGap: 10,
            gridAutoFlow: ['row', 'column'],
            gridAutoColumns: ['100%', '210px'],
            justifyContent: 'flex-start',
            pb: [20, 30, 40],
          }}
        >
          <Button as={Link} to="/accounts/signup/" type={ButtonTypeEnum.DARK}>
            Get API keys
          </Button>
          {ppc ? null : (
            <Button as={Link} to="/docs/" type={ButtonTypeEnum.DARK} ghost>
              Read docs
            </Button>
          )}
        </Box>
        <Box
          sx={{
            mt: [20, 20],
            mb: '8px',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(6, 1fr)'],
            gridGap: '8px',
            height: 'auto',
          }}
        >
          <SmallCard
            number={<IconFile />}
            text="Original image"
            illustration={
              <Flex
                width="100%"
                sx={{
                  pl: ['0px', '0px', '5%', '0px'],
                  pr: ['3px', '0px', '5%', '0px'],
                  pb: ['10px', '7px', '10%', '20px'],
                  pt: ['5px', '0px'],
                  justifyContent: ['flex-end', 'center'],
                }}
              >
                <Box
                  as={BlImage}
                  width="161px"
                  data-blink-src="https://ucarecdn.com/af1c686c-33b3-43ea-820b-eae262f78fe8/step0-original-image.jpg"
                  data-blink-quality="smart_retina"
                  alt="file uploaded, it is a photo of shoes"
                  sx={{ alignSelf: 'flex-end' }}
                />
              </Flex>
            }
          />
          <SmallCard
            number="1"
            text="Getting destination properties"
            illustration={
              <Flex
                width="100%"
                sx={{
                  pl: ['0px', '7px', '7px', '10px'],
                  pr: ['8px', '7px', '7px', '10px'],
                  pt: '10px',
                  justifyContent: ['flex-end', 'center'],
                }}
              >
                <Box
                  as={BlImage}
                  width="160px"
                  data-blink-src="https://ucarecdn.com/c3d80311-98c5-4ee9-abb1-a19bf44389ec/step1-getting-destination-properties.png"
                  data-blink-quality="smart_retina"
                  alt="for a photo, text on image reads 780x780 DPR2.0"
                  sx={{ alignSelf: 'flex-end' }}
                />
              </Flex>
            }
          />
          <SmallCard
            number="2"
            text="Resizing and cropping"
            illustration={
              <Flex
                width="100%"
                sx={{
                  pb: ['7px', '7px', '7px', '10px'],
                  pt: ['6px', '0px'],
                  justifyContent: ['flex-end', 'start'],
                }}
              >
                <Box
                  as={BlImage}
                  width="180px"
                  data-blink-src="https://ucarecdn.com/3bb21cdb-5863-4096-b3d0-45587fdaa6a7/step2-resizing-and-cropping.jpg"
                  data-blink-quality="smart_retina"
                  alt="with smart resize feature on the original image of shoes"
                  sx={{ alignSelf: 'flex-end' }}
                />
              </Flex>
            }
          />
          <SmallCard
            number="3"
            text="Generating missing background"
            illustration={
              <Flex
                width="100%"
                sx={{
                  p: ['12px', '9%', '10%', '18px'],
                  justifyContent: ['flex-end', 'center'],
                }}
              >
                <Box
                  as={BlImage}
                  width="146px"
                  data-blink-src="https://ucarecdn.com/1490a240-9fda-434d-b432-47e6faabab54/step3-generating-missing-background.jpg"
                  data-blink-quality="smart_retina"
                  alt="transforming the photo of shoes"
                  sx={{ alignSelf: 'flex-end' }}
                />
              </Flex>
            }
          />
          <SmallCard
            number="4"
            text="Enhancing"
            illustration={
              <Flex
                width="100%"
                sx={{
                  p: ['12px', '9%', '10%', '18px'],
                  justifyContent: ['flex-end', 'center'],
                }}
              >
                <Box
                  as={BlImage}
                  width="146px"
                  data-blink-src="https://ucarecdn.com/4deb1aea-560f-470e-af10-3d682e963a45/step4-enhancing.jpg"
                  data-blink-quality="smart_retina"
                  alt="to provide more professional look to shoe photo"
                  sx={{ alignSelf: 'flex-end' }}
                />
              </Flex>
            }
          />
          <SmallCard
            number="5"
            last
            text="Picking the best compression level"
            illustration={
              <Flex
                sx={{
                  width: '100%',
                  p: ['12px', '9%', '10%', '18px'],
                  pt: ['12px', '0px'],
                  justifyContent: ['flex-end', 'center'],
                }}
              >
                <Box
                  as={BlImage}
                  width="146px"
                  data-blink-src="https://ucarecdn.com/9e848013-188e-4442-8616-173135eac5b5/step5-picking-the-best-compression-level.jpg"
                  data-blink-quality="smart_retina"
                  alt="automatically compressing the product image"
                  sx={{ alignSelf: 'flex-end' }}
                />
              </Flex>
            }
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: ['auto', 720, 360, 360],
            backgroundColor: grayBgr,
            borderRadius: cornerRadius,
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Flex
            sx={{
              position: ['relative', 'absolute'],
              bottom: ['unset', '360px', 0],
              pt: ['30px', '0px'],
              pl: ['40px', '0px'],
              right: ['unset', 0, '16%', 240],
              width: ['auto', '100%', 'auto'],
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom: '1px solid #DEE4EA',
            }}
          >
            <Box
              as={BlImage}
              width={['400px', '475px']}
              maxWidth="120%"
              data-blink-src="https://ucarecdn.com/1a86c6b8-4e77-44c2-9da7-4228ad9a2dd8/result.jpg"
              data-blink-quality="smart_retina"
              alt="Fully optimized product image delivery to a smartphone"
              sx={{
                mr: ['-20px', '0px'],
                alignSelf: 'flex-end',
              }}
            />
          </Flex>
          <Box
            sx={{
              position: 'absolute',
              top: [-18, 0],
              right: ['unset', 15],
              left: [15, 'unset'],
              height: '130px',
              width: ['75px', '24%', '20%', '280px'],
              display: 'grid',
              gridTemplateColumns: '1fr 30px',
            }}
          >
            <Flex
              sx={{
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box width="100%" pt="32px">
                <Box
                  as={Image}
                  width={['75px', '96px']}
                  src="https://ucarecdn.com/cbef2385-f477-4e3f-a25f-c6fb085db9ed/resultparams.svg"
                />
              </Box>

              <Box
                sx={{
                  position: 'relative',
                  overflow: 'hidden',
                  height: '14px',
                  width: '100%',
                  display: ['none', 'initial'],
                }}
              >
                <StyledResultArrow />
              </Box>
            </Flex>
            <Box
              sx={{
                position: 'relative',
                overflow: 'hidden',
                height: '100%',
                width: '30px',
                display: ['none', 'initial'],
              }}
            >
              <StyledResultTurn />
            </Box>
          </Box>
          <Flex
            sx={{
              mt: ['2%', '360px', 0],
              py: ['6%', 50],
              px: ['6%', 50, 50, 70],
              height: ['auto', '50%', '100%'],
              flexDirection: 'column',
              alignContent: ['start', 'center', 'start'],
              alignItems: ['start', 'center', 'start'],
              justifyContent: 'center',
            }}
          >
            <Box
              as="ul"
              sx={{
                textAlign: ['left', 'center', 'left'],
                zIndex: 0,
              }}
            >
              <ListItem text="Art direction on autopilot" />
              <ListItem text="AI-driven compression" />
              <ListItem text="On-the-fly processing" />
              <ListItem text="CDN included" />
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  </Flex>
);
