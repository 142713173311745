import React from 'react';
import { Box } from 'rebass/styled-components';

export const IllustrationCard = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: ['auto', '100px', '133px', '160px'],
        backgroundColor: '#F6F6F6',
        ...sx,
      }}
      {...props}
    />
  );
};
